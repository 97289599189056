import { gql } from '__generated__';

export const IMAGE_FRAGMENT = gql(`
  fragment image on AssetInterface {
    ...on assetsVolume_Asset {
      __typename
      id
      mimeType
      title
      uid
      url
      height
      width
      alt
    }
  }
`);
